@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Aeonik-Regular.eot'); /* IE9 and above */
  src: local('Aeonik'), url('../../fonts/Aeonik-Regular.woff') format('woff'),
    url('../../fonts/Aeonik-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE8 */ url('../../fonts/Aeonik-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/Aeonik-Medium.eot'); /* IE9 and above */
  src: local('Aeonik'), url('../../fonts/Aeonik-Medium.woff') format('woff'),
    url('../../fonts/Aeonik-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE8 */ url('../../fonts/Aeonik-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Aeonik';
  font-style: bold;
  font-weight: 700;
  src: url('../../fonts/Aeonik-Bold.eot'); /* IE9 and above */
  src: local('Aeonik'), url('../../fonts/Aeonik-Bold.woff') format('woff'),
    url('../../fonts/Aeonik-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE8 */ url('../../fonts/Aeonik-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

body {
  background-color: #fff;
}

@media screen and (min-width: 1368px) {
  body {
    background-color: #f5f6fa;
  }
}
